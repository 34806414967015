<template>
  <div />
</template>

<script>
export default {
  mounted() {
    // const { retInfo } = this.$route.query

    // const data = {
    //   type: 'verificationResult',
    //   retInfo,
    // }

    // try {
    //   window.opener.postMessage(data, window.location.origin)
    //   window.self.close()
    // } catch (err) {
    //   console.log(err)
    // }
  },
}
</script>
